import { get, isDemo, post } from './utils.service'
export const signin = async (body) => {
    const res = await post(`/auth/signin`, {
        ...body,
    })
    return await res?.json()
}

export const register = async (body) => {
    const res = await post(`/auth/register`, { ...body })
    return await res?.json()
}

export const getRefreshToken = async () => {
    try {
        const res = await get(`/auth/refreshToken`)
        const res_json = await res?.json()
        // throw new Error("Not implemented");
        if ((res_json && res_json?.token && res_json?.token?.split('.').length === 3) || isDemo()) {
            localStorage.setItem('token', res_json.token)
        } else {
            // localStorage.removeItem("token");
            // // change location to login
            // window.location.href = "/login";
            // // window.location.reload();
            await logout()
        }
    } catch (e) {
        console.log('getRefreshToken', e)
        await logout()
    }
}

export const logout = async () => {
    await get(`/auth/logout`)
    localStorage.removeItem('token')
    localStorage.removeItem('calendar')
    document.cookie = 'token=; Max-Age=-99999999; Domain=a-grid.com; path=/'
    document.cookie = 'token=; Max-Age=-99999999; Domain=a-grid.com; path=/auth'
    document.cookie = 'token=; Max-Age=-99999999; Domain=a-grid.com; path=/admin'
    document.cookie = 'token=; Max-Age=-99999999; Domain=a-grid.com; path=*'
    setTimeout(() => {
        window.location.href = '/login'
    }, 300)
}

export const resetPassword = async (body) => {
    const res = await post(`/auth/reset-password`, { ...body })
    return await res?.json()
}
