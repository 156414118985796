import PilotImg from '../../assets/img/help/placeholders/pilot.png'
import ConfigImg from '../../assets/img/help/placeholders/configuration.png'
import InterfaceImg from '../../assets/img/help/placeholders/interface.png'
import AllImg from '../../assets/img/help/placeholders/all.png'
import ReceptionImg from '../../assets/img/help/placeholders/reception.png'
import TechnicalImg from '../../assets/img/help/placeholders/tech.png'
import DirectionImg from '../../assets/img/help/placeholders/direction.png'
import Tuto1 from '../../assets/img/help/tuto_1.png'
import Tuto3 from '../../assets/img/help/tuto_3.png'
import Tuto4 from '../../assets/img/help/tuto_4.png'
import Tuto5 from '../../assets/img/help/tuto_5.png'
import Tuto6 from '../../assets/img/help/tuto_6.png'
import Tuto7 from '../../assets/img/help/tuto_7.png'
import Tuto8 from '../../assets/img/help/tuto_8.png'
import Tuto9 from '../../assets/img/help/tuto_9.png'
import Tuto21 from '../../assets/img/help/tuto_2.1.png'
import Tuto22 from '../../assets/img/help/tuto_2.2.png'
import Tuto10 from '../../assets/img/help/tuto_10.png'
import Tuto11 from '../../assets/img/help/tuto_11.png'
import Tuto13 from '../../assets/img/help/tuto_13.png'
import Tuto14 from '../../assets/img/help/tuto_14.png'
import Tuto15 from '../../assets/img/help/tuto_15.png'
// dashboard/src/assets/img/help/placeholders/questions/complaint.png dashboard/src/assets/img/help/placeholders/questions/add_user.png dashboard/src/assets/img/help/placeholders/questions/agrid.png dashboard/src/assets/img/help/placeholders/questions/bug.png dashboard/src/assets/img/help/placeholders/questions/cold.png dashboard/src/assets/img/help/placeholders/questions/connexion.png dashboard/src/assets/img/help/placeholders/questions/contact.png dashboard/src/assets/img/help/placeholders/questions/difference.png dashboard/src/assets/img/help/placeholders/questions/email.png dashboard/src/assets/img/help/placeholders/questions/misunderstanding.png dashboard/src/assets/img/help/placeholders/questions/mode.png dashboard/src/assets/img/help/placeholders/questions/not_working.png dashboard/src/assets/img/help/placeholders/questions/risks.png dashboard/src/assets/img/help/placeholders/questions/savings.png dashboard/src/assets/img/help/placeholders/questions/target.png dashboard/src/assets/img/help/placeholders/questions/temp_not_working.png
import ComplaintImg from '../../assets/img/help/placeholders/questions/complaint.png'
import AddUserImg from '../../assets/img/help/placeholders/questions/add_user.png'
import AgridImg from '../../assets/img/help/placeholders/questions/agrid.png'
import BugImg from '../../assets/img/help/placeholders/questions/bug.png'
import ColdImg from '../../assets/img/help/placeholders/questions/cold.png'
import ConnexionImg from '../../assets/img/help/placeholders/questions/connexion.png'
import ContactImg from '../../assets/img/help/placeholders/questions/contact.png'
import DifferenceImg from '../../assets/img/help/placeholders/questions/difference.png'
import EmailImg from '../../assets/img/help/placeholders/questions/email.png'
import MisunderstandingImg from '../../assets/img/help/placeholders/questions/misunderstanding.png'
import ModeImg from '../../assets/img/help/placeholders/questions/mode.png'
import NotWorkingImg from '../../assets/img/help/placeholders/questions/not_working.png'
import RisksImg from '../../assets/img/help/placeholders/questions/risks.png'
import SavingsImg from '../../assets/img/help/placeholders/questions/savings.png'
import TargetImg from '../../assets/img/help/placeholders/questions/target.png'
import SendCommand from '../../assets/img/help/placeholders/questions/send_command.png'
import Emergency from '../../assets/img/help/placeholders/questions/emergency.png'
import Realtime from '../../assets/img/help/placeholders/questions/realtime.png'
import GroupImg from '../../assets/img/help/placeholders/questions/group.png'
import ZoneConfigImg from '../../assets/img/help/placeholders/questions/zone_config.png'
import SiteConfigImg from '../../assets/img/help/placeholders/questions/site_config.png'
import ToggleImg from '../../assets/img/help/placeholders/questions/toggle.png'
import ChangeTmpImg from '../../assets/img/help/placeholders/questions/change_tmp.png'
import SaveZoneImg from '../../assets/img/help/placeholders/questions/save_zone.png'
import { PMSQuestion } from './components/questions/pms.question'

const ANY = 'tag_any'
const DASHBOARD = 'tag_dashboard'
const PILOT = 'tag_pilot'
const CONFIGURATION = 'tag_configuration'
const RECEPTION = 'tag_reception'
const TECHNICAL = 'tag_technical'
const OWNER = 'tag_owner'

export const tags = [
    { target: DASHBOARD, label: 'Interface', section: 1, img: InterfaceImg },
    { target: PILOT, label: 'Pilotage', section: 1, img: PilotImg },
    { target: CONFIGURATION, label: 'Configuration', section: 1, img: ConfigImg },
    {
        target: ANY,
        label: 'Fonctionnement du pilote AGRID',
        section: 1,
        img: AllImg,
    },
    { target: RECEPTION, label: 'Réception et équipe opérationnelle', section: 2, img: ReceptionImg },
    { target: TECHNICAL, label: 'Responsables techniques', section: 2, img: TechnicalImg },
    { target: OWNER, label: 'Propriétaires - Gestionnaires - Directions', section: 2, img: DirectionImg },
]

export const questions = [
    {
        label: 'Mon client/locataire a froid',
        featured: true,
        description: `
        
        <section>
            <p>
                Dans un premier temps, assurez-vous que le client a bien allumé le système de chauffage ou de climatisation dans sa chambre. 
                Si ce n’est pas le cas, invitez votre client à allumer son système ou bien faites-le pour lui, directement depuis la plateforme AGRID 
            </p>
            
            <p>
                Si le système est allumé mais que le client a quand même chaud/froid, alors il vous faudra passer une consigne depuis l’interface 
                (<a href="/help?id=t9">lien vers cette question</a>).
            </p>
            
            <p>
                ℹ️ Il faut savoir que sur certains modèles de télécommandes murales de marque Daikin, la température reste visible même lorsque le système 
                CVC (climatisation/ventilation/chauffage) est éteint.
            </p>
            
            <p>
                Il est donc possible que le client modifie la consigne de température sur la télécommande murale, sans que ces changements ne soient pris en compte, 
                simplement parce que le système est à l'arrêt.
            </p>
            
            <h3>Notre conseil :</h3>
            <p>
                Avant de modifier la consigne de température, nous recommandons d'informer le client de la température actuelle dans sa chambre. 
                Parfois, la sensation de chaleur ou de froid peut être influencée par des éléments externes (vêtements, activité physique, température extérieure).
            </p>
            
            <p>
                Pour indiquer la température qu’il fait en chambre : sélectionnez la chambre et passez votre souris sur le graphique; 
                la température apparaîtra (dans l’exemple, il faisait 20,1°C à 15h30 le 30/10).
            </p>

            <img src="${Tuto15}" alt="Tuto 8" class="help-image-2" style="max-width: 100%;"/>
            
            <p>
                Pour aider le client à mieux apprécier la température réelle, vous pouvez lui indiquer la température mesurée dans sa chambre. 
                Cela permet souvent de confirmer si un ajustement est nécessaire.
            </p>

            <h3>Suggestion de formulation pour le réceptionniste :</h3>
            <blockquote>
                “Très bien Madame/Monsieur. Actuellement, la température de votre chambre est de XX°C. Souhaitez-vous que nous ajustions la température dans votre chambre ?”
            </blockquote>
        </section>
        `,
        summary:
            'Vérifiez si le client a allumé le chauffage ou la climatisation. Les changements sur la télécommande peuvent ne pas être pris en compte si le système est éteint.',
        id: 1,
        tags: [PILOT, RECEPTION, TECHNICAL],
        emoji: '🥶',
        img: ColdImg,
    },
    {
        label: 'Changer de mode',
        featured: true,
        description: `
        <section>
            <p>
                Chez Agrid, nous ne changeons pas le mode de votre climatiseur (chaud, froid, auto, etc.) pour des raisons techniques et de sécurité. 
                Changer le mode d’un climatiseur connecté n’est pas anodin, car tous les climatiseurs reliés au même moteur (par exemple, dans un système centralisé) 
                doivent fonctionner dans le même mode pour garantir la stabilité et éviter tout dommage.
            </p>
            
            <p>
                En effet, si un climatiseur passe en mode froid tandis qu’un autre sur le même moteur fonctionne en mode chaud, 
                cela peut provoquer des pressions contradictoires dans le moteur, ce qui risque de l’endommager à long terme. 
                C’est pourquoi Agrid s’abstient de changer le mode de fonctionnement de vos climatiseurs de manière automatique.
            </p>
            
            <p>
                Si vous constatez que le mode de votre climatiseur a changé sans intervention de votre part, 
                il est recommandé de vérifier avec votre mainteneur ou de contacter un professionnel, car ce changement n’a pas été initié par Agrid.
            </p>
            
            <h3>Notre conseil :</h3>
            <p>
                Pour une gestion optimale et la sécurité de vos équipements, assurez-vous que tous les climatiseurs reliés à un même moteur fonctionnent dans un seul mode 
                (soit chaud, soit froid, soit en mode auto) selon la saison et les besoins de l’établissement. 
                En cas de besoin spécifique, nous vous encourageons à consulter votre mainteneur pour réaliser les changements de mode en toute sécurité.
            </p>
        </section>
        `,
        summary:
            "Agrid ne modifie pas le mode de votre climatiseur pour éviter d'endommager les moteurs. Contactez votre mainteneur si le mode a changé.",
        id: 2,
        tags: [CONFIGURATION, TECHNICAL],
        emoji: '🔄',
        img: ModeImg,
    },
    {
        label: "Faire plus d'économies",
        featured: true,
        description: `
    <section>
        <p>
            Il est toujours possible d’optimiser davantage les économies d’énergie avec Agrid. Notre algorithme d’intelligence artificielle 
            fonctionne en respectant strictement les bornes de température que vous avez configurées pour chaque zone ou chambre. 
            Cela signifie qu’il ajuste le système de chauffage, ventilation et climatisation (CVC) pour optimiser la consommation énergétique tout en maintenant le confort.
        </p>

        <p>
            En élargissant légèrement ces bornes de température, vous donnez à l’algorithme davantage de flexibilité pour adapter la gestion thermique de votre établissement 
            selon les conditions extérieures et l’occupation, ce qui lui permet de générer encore plus d’économies.
        </p>

        <h3>Comment ajuster les bornes de température :</h3>
        <p>
            Pour ajuster les bornes de température, rendez-vous dans la section "Configuration" de votre compte Agrid. 
            Vous pourrez y définir les limites de confort pour chaque zone en fonction de la saison ou des besoins spécifiques. 
            Une fois ces paramètres mis à jour, l’algorithme optimisera les réglages pour exploiter au mieux ces nouvelles marges de manoeuvre, 
            augmentant ainsi les économies potentielles.
        </p>

        <h3>Notre recommandation :</h3>
        <p>
            Nous recommandons de trouver un juste équilibre entre les limites de température et le confort de vos clients. 
            Même un léger ajustement, comme élargir d’un degré les bornes de température, peut offrir une amélioration significative des économies d’énergie sans compromettre le confort.
        </p>
    </section>
    `,
        summary:
            "Modifiez les bornes de température pour permettre à l'intelligence artificielle d'Agrid d'améliorer encore plus les économies d'énergie.",
        id: 3,
        tags: [ANY, OWNER],
        emoji: '💰',
        img: SavingsImg,
    },
    {
        featured: true,
        label: 'Je ne sais pas avec quelle adresse mail me connecter ',
        description: `
    <section>
        <p>
            En tant qu’administrateur(trice) de votre compte AGRID, l’adresse e-mail à utiliser pour vous connecter vous sera communiquée par l’équipe AGRID lors de la configuration initiale. 
            Cette adresse e-mail est associée à vos droits d’administrateur et vous permet de gérer les paramètres de votre espace.
        </p>
        
        <p>
            Si vous êtes un utilisateur classique (non administrateur), votre accès dépend de votre administrateur(trice). Celui-ci/celle-ci peut facilement ajouter votre adresse e-mail 
            en accédant à l’onglet « Configuration » sur la plateforme AGRID et en vous inscrivant en tant qu’utilisateur autorisé. 
            Une fois ajouté, vous recevrez un e-mail de confirmation et pourrez vous connecter avec cette adresse.
        </p>
        
        <h3>Conseil :</h3>
        <p>
            Si vous ne savez pas quelle adresse e-mail utiliser, contactez directement votre administrateur(trice) ou l’équipe AGRID pour obtenir de l’aide.
        </p>
    </section>
    `,
        summary:
            "L'administrateur peut ajouter votre adresse e-mail via l'onglet Configuration sur la plateforme AGRID.",
        id: 4,
        tags: [DASHBOARD, RECEPTION],
        emoji: '📧',
        img: EmailImg,
    },
    {
        label: 'Connexion - Je ne connais pas mon mot de passe ?',
        description: `
    <section>
        <p>
            Si vous avez oublié votre mot de passe, il est très facile de le réinitialiser. Accédez à la page de connexion de la plateforme AGRID et cliquez sur le bouton « Mot de passe oublié ». 
            Vous recevrez alors un e-mail contenant un lien de réinitialisation pour définir un nouveau mot de passe.
        </p>
        
        <p>
            Pour protéger votre compte, AGRID utilise une double authentification. Après avoir réinitialisé votre mot de passe, 
            un code de vérification vous sera demandé lors de la connexion pour sécuriser davantage vos informations et garantir la confidentialité de vos données.
        </p>
        
        <h3>Remarque :</h3>
        <p>
            Assurez-vous que l’e-mail de réinitialisation n’a pas été envoyé dans votre dossier de courrier indésirable ou de spam. 
            Si vous ne recevez pas l’e-mail de réinitialisation, contactez l’assistance AGRID pour obtenir de l’aide.
        </p>
    </section>
    `,
        summary:
            'Utilisez le bouton « mot de passe oublié » pour réinitialiser votre mot de passe. La double authentification sécurise votre compte.',
        id: 5,
        tags: [DASHBOARD, RECEPTION],
        emoji: '🔑',
        img: ConnexionImg,
    },
    {
        label: 'Configuration – Comment ajouter un utilisateur ?',
        description: `
        <section>
            <p>
                En tant qu’administrateur(trice) de la plateforme AGRID, vous avez la possibilité d’ajouter ou de supprimer des utilisateurs 
                afin de gérer l’accès à votre espace de manière sécurisée et contrôlée.
            </p>
            
            <h3>Étapes pour ajouter un utilisateur :</h3>
            <ol>
                <li>Accédez à l’onglet « Configuration » de votre interface AGRID.</li>
                <li>Sélectionnez « Configuration du site » pour voir les paramètres d’administration.</li>
                <li>Dans la section dédiée aux utilisateurs, choisissez l’option pour ajouter un nouvel utilisateur.</li>
                <li>Définissez le statut de cet utilisateur en choisissant entre « Admin » (pour les utilisateurs ayant des droits d’administration) ou « Utilisateur » (pour un accès limité).</li>
                <li>Sauvegardez les modifications, et un e-mail d’invitation sera envoyé automatiquement à l’utilisateur pour finaliser son accès.</li>
            </ol>
            
            <p>
                En cas de besoin, vous pouvez également retirer un utilisateur en suivant les mêmes étapes et en sélectionnant l’option de suppression.
            </p>
        </section>
        `,
        summary:
            "Ajoutez ou supprimez des utilisateurs dans l'onglet Configuration en choisissant le statut Admin ou Utilisateur.",
        id: 6,
        tags: [CONFIGURATION, OWNER],
        emoji: '👥',
        img: AddUserImg,
    },
    {
        label: 'Configuration – Je ne sais plus quelles sont les adresses emails autorisées à se connecter à l’interface ?',
        description: `
        <section>
            <p>
                Il est possible de consulter à tout moment la liste des adresses e-mails autorisées à se connecter à la plateforme AGRID. 
                Cette fonctionnalité permet de vérifier rapidement les utilisateurs ayant accès et de gérer les autorisations de manière proactive.
            </p>
            
            <h3>Comment accéder à la liste des e-mails autorisés :</h3>
            <ol>
                <li>Accédez à l’onglet « Configuration » de votre interface AGRID.</li>
                <li>Dans la section « Utilisateurs » ou « Configuration du site », vous verrez la liste des adresses e-mails autorisées.</li>
                <li>Cette liste indique tous les utilisateurs enregistrés, leur statut (Admin ou Utilisateur), et vous permet de modifier leurs droits si nécessaire.</li>
            </ol>
            
            <p>
                En cas de doute, cette consultation permet de s’assurer que seuls les utilisateurs autorisés ont accès à la plateforme, 
                contribuant ainsi à la sécurité de vos informations.
            </p>
        </section>
        `,
        summary: "Consultez la liste des adresses e-mails autorisées dans l'onglet Configuration de l'interface AGRID.",
        id: 7,
        tags: [CONFIGURATION, OWNER],
        emoji: '📧',
        img: ContactImg,
    },
    {
        label: 'Je ne parviens pas à changer une température depuis l’interface ?',
        description: `
        <section>
            <p>
                Si vous rencontrez des difficultés pour changer la température depuis l’interface AGRID, voici quelques étapes pour résoudre le problème :
            </p>
            
            <h3>Étapes de vérification :</h3>
            <ol>
                <li>
                    Assurez-vous de suivre correctement les étapes décrites dans le guide de modification des consignes de température.
                </li>
                <li>
                    Après avoir saisi la nouvelle température, vérifiez que vous avez bien cliqué sur le bouton « Enregistrer » pour confirmer le changement.
                </li>
                <li>
                    Notez que la modification peut prendre quelques secondes avant de s’afficher dans l’interface. Cette latence est normale et permet à AGRID de valider et synchroniser les données.
                </li>
            </ol>
            
            <p>
                Si le problème persiste malgré ces étapes, il est recommandé de contacter le support AGRID pour obtenir de l’assistance supplémentaire.
            </p>
        </section>
        `,
        summary:
            "Assurez-vous d'avoir cliqué sur « Enregistrer » après modification. Les changements peuvent prendre quelques secondes pour apparaître.",
        id: 8,
        tags: [DASHBOARD, RECEPTION],
        emoji: '🌡️',
        img: ChangeTmpImg,
    },
    {
        label: 'Certaines températures ne « fonctionnent » pas ?',
        description: `
    <section>
        <p>
            Si vous constatez que certaines consignes de température ne sont pas appliquées comme prévu, cela peut être dû aux limites de température configurées pour garantir le confort 
            et l’efficacité énergétique. AGRID applique des bornes de température pour empêcher des consignes extrêmes qui pourraient nuire à l'économie d'énergie et au confort.
        </p>
        
        <h3>Règles de fonctionnement des bornes de température :</h3>
        <ul>
            <li>
                Seules les consignes de température respectant les bornes définies dans la configuration du site sont autorisées. 
                Si une consigne dépasse ces bornes, elle sera automatiquement ajustée par le système pour rester dans les limites.
            </li>
            <li>
                Les bornes sont fixées en fonction des meilleures pratiques pour optimiser l'efficacité tout en garantissant un confort optimal.
            </li>
        </ul>
        
        <p>
            Pour ajuster ces bornes, vous pouvez accéder aux paramètres de configuration sur l’interface AGRID. 
            Cela permet de personnaliser les limites en fonction de vos besoins spécifiques.
        </p>
    </section>
    `,
        summary:
            'Les températures hors bornes configurées sont corrigées automatiquement. Seules les consignes dans les bornes sont appliquées.',
        id: 9,
        tags: [PILOT, ANY, RECEPTION],
        emoji: '🌡️',
        img: DifferenceImg,
    },
    {
        label: 'Je ne comprends pas une consigne passée automatiquement ?',
        description: `
    <section>
        <p>
            Le pilote automatique d’AGRID est conçu pour gérer les consignes de température dans les limites configurées pour votre bâtiment. 
            Cette fonction assure que chaque réglage est optimisé pour maximiser l’économie d’énergie tout en préservant le confort de vos clients.
        </p>
        
        <h3>Explications sur le fonctionnement du pilote automatique :</h3>
        <ul>
            <li>
                Le système ajuste automatiquement les consignes en fonction des bornes de température définies, des conditions extérieures et de l’occupation des chambres ou des zones.
            </li>
            <li>
                Si une consigne automatique semble inhabituelle, cela signifie probablement qu’elle a été ajustée pour se conformer aux limites optimales d'économie énergétique. 
                Le but est de garantir une température confortable sans gaspillage d’énergie.
            </li>
        </ul>
        
        <p>
            En cas de besoin, vous pouvez consulter les bornes de température dans l’onglet « Configuration » pour mieux comprendre comment elles influencent les consignes automatiques.
        </p>
    </section>
    `,
        summary:
            'Le pilote automatique ajuste les consignes dans les bornes définies pour optimiser la consommation énergétique et maintenir le confort.',
        id: 10,
        tags: [ANY, RECEPTION],
        emoji: '🌡️',
        img: MisunderstandingImg,
    },
    {
        label: 'La température demandée n’est pas atteinte ?',
        description: `
        <section>
            <p>
                Dans certaines conditions météorologiques extrêmes, il est possible que le système CVC ne parvienne pas à atteindre la température demandée dans une pièce. 
                En cas de forte chaleur ou de froid intense, le système peut être limité par sa capacité à compenser ces variations, surtout si le bâtiment n’a pas une isolation optimale.
            </p>
            
            <h3>Facteurs influençant l’efficacité du système :</h3>
            <ul>
                <li>
                    Les températures extérieures élevées ou très basses : Lorsque les températures extérieures sont extrêmes, 
                    le système CVC doit fournir un effort important pour maintenir la température intérieure, ce qui peut parfois dépasser sa capacité.
                </li>
                <li>
                    La puissance du système CVC : Si le système n’est pas dimensionné pour les conditions climatiques actuelles, 
                    il peut ne pas être capable d’atteindre la température souhaitée.
                </li>
            </ul>
            
            <p>
                Dans ces situations, nous recommandons d’informer le client que le système est limité par des facteurs externes et d’ajuster les attentes en fonction des conditions.
            </p>
        </section>
        `,
        summary:
            "Les températures extrêmes extérieures peuvent empêcher le système CVC d'atteindre la température demandée.",
        id: 11,
        tags: [ANY, OWNER],
        emoji: '🌡️',
        img: TargetImg,
    },
    {
        label: 'La température ressentie n’est pas celle mesurée dans la pièce ?',
        description: `
    <section>
        <p>
            Il est fréquent que la température ressentie par les occupants d’une pièce diffère de la température mesurée par les sondes de votre système CVC. 
            Cela peut être dû à l’emplacement des sondes, qui se trouvent souvent dans les unités de climatisation ou les télécommandes murales, 
            ce qui ne reflète pas toujours précisément la température ambiante ressentie dans toute la pièce.
        </p>
        
        <h3>Points de vérification :</h3>
        <ul>
            <li>
                <strong>Emplacement des sondes :</strong> Les sondes placées près de sources de chaleur ou de froid, comme des fenêtres ou des murs exposés, 
                peuvent afficher des températures différentes de celles ressenties au centre de la pièce.
            </li>
            <li>
                <strong>Bon fonctionnement des sondes :</strong> Assurez-vous que les sondes fonctionnent correctement et qu’elles sont bien entretenues. 
                Des sondes défectueuses peuvent indiquer des températures incorrectes.
            </li>
        </ul>
        
        <p>
            Si les écarts persistent, envisagez de repositionner les sondes ou de les étalonner pour mieux refléter les conditions réelles dans la pièce.
        </p>
    </section>
    `,
        summary: "La température mesurée peut varier selon l'emplacement des sondes. Vérifiez leur bon fonctionnement.",
        id: 12,
        tags: [ANY, TECHNICAL],
        emoji: '🌡️',
        img: BugImg,
    },
    {
        label: 'Un client se plaint de la température dans sa chambre ?',
        description: `
    <section>
        <p>
            Les plaintes des clients concernant la température de leur chambre peuvent être liées à plusieurs facteurs, comme le dimensionnement du système CVC ou des réglages inadéquats. 
            Lorsqu’un client exprime une insatisfaction, il est possible de répondre à ses besoins en désactivant temporairement le pilote automatique pour sa chambre.
        </p>
        
        <h3>Étapes pour répondre à une plainte :</h3>
        <ol>
            <li>
                <strong>Vérifiez le dimensionnement du système :</strong> Si le système CVC est sous-dimensionné pour la chambre ou pour le climat extérieur actuel, 
                il peut être difficile d’atteindre la température souhaitée.
            </li>
            <li>
                <strong>Désactivez le pilote automatique :</strong> Pour la chambre concernée, vous pouvez désactiver le pilote automatique, 
                ce qui permettra de passer des consignes spécifiques pour ajuster la température selon la demande du client.
            </li>
        </ol>
        
        <p>
            Communiquez avec le client pour lui expliquer les limites éventuelles du système et proposer des solutions de réglage adaptées à ses attentes.
        </p>
    </section>
    `,
        summary:
            'Désactivez le pilote automatique pour une chambre si un client se plaint de la température durant son séjour.',
        id: 13,
        tags: [ANY, RECEPTION],
        emoji: '🌡️',
        img: ComplaintImg,
    },
    {
        label: 'Un client me demande en quoi consiste AGRID ?',
        description: `
        <section>
            <p>
                AGRID est une solution innovante conçue pour réduire l'empreinte carbone des bâtiments tertiaires, tels que les hôtels et les bureaux, 
                en optimisant l'utilisation des systèmes de chauffage, ventilation et climatisation (CVC). 
                Grâce à une technologie intelligente, AGRID adapte en temps réel les consignes de température en fonction des besoins réels de l’établissement.
            </p>
            
            <h3>Fonctionnement d’AGRID :</h3>
            <ul>
                <li>Utilisation de l’intelligence artificielle pour optimiser les consignes de température tout en maintenant le confort des occupants.</li>
                <li>Réduction de la consommation énergétique des systèmes CVC, permettant des économies significatives.</li>
                <li>Préservation de l’environnement en réduisant l’empreinte carbone de l’établissement.</li>
            </ul>
            
            <p>
                En résumé, AGRID est un outil efficace pour les établissements cherchant à améliorer leur efficacité énergétique et à réduire leurs coûts 
                tout en répondant aux objectifs environnementaux.
            </p>
        </section>
        `,
        summary:
            "AGRID optimise l’utilisation des systèmes CVC pour réduire la consommation énergétique et l'impact carbone des bâtiments.",
        id: 14,
        tags: [ANY, OWNER],
        emoji: '🌡️',
        img: AgridImg,
    },
    {
        label: 'Mon système CVC ne semble plus fonctionner ?',
        description: `
    <section>
        <p>
            Si votre système CVC semble ne plus fonctionner correctement, il est possible que le boîtier AGRID ait rencontré un problème temporaire. 
            Dans ce cas, un simple redémarrage peut souvent résoudre le problème.
        </p>
        
        <h3>Étapes de dépannage :</h3>
        <ol>
            <li>Débranchez le boîtier AGRID de son alimentation.</li>
            <li>Patientez quelques secondes, puis rebranchez le boîtier.</li>
            <li>Une fois reconnecté, le pilotage automatique d’AGRID reprendra et les consignes seront à nouveau appliquées.</li>
        </ol>
        
        <p>
            Si le problème persiste après le redémarrage, contactez l’assistance AGRID pour obtenir de l’aide.
        </p>
    </section>
    `,
        summary: 'Débranchez puis rebranchez le boîtier AGRID pour vérifier si le problème provient du système AGRID.',
        id: 15,
        tags: [ANY, TECHNICAL],
        emoji: '🌡️',
        img: NotWorkingImg,
    },
    {
        label: 'Quels sont les risques pour mes systèmes ?',
        description: `
    <section>
        <p>
            AGRID est conçu pour être entièrement non-intrusif, ce qui signifie qu'il ne modifie pas physiquement vos systèmes CVC et ne présente aucun risque d'endommagement. 
            En se comportant comme un utilisateur standard, AGRID se contente de transmettre des consignes de température aux unités de climatisation et de chauffage.
        </p>
        
        <h3>Pourquoi AGRID est sûr pour vos équipements :</h3>
        <ul>
            <li>AGRID n’intervient pas dans le fonctionnement interne de vos systèmes CVC.</li>
            <li>Il fonctionne comme un utilisateur externe, envoyant simplement des consignes pour optimiser l’efficacité énergétique.</li>
            <li>AGRID n'exerce pas de pression excessive sur vos systèmes et respecte les limites de fonctionnement pour éviter tout impact négatif.</li>
        </ul>
        
        <p>
            Vous pouvez donc utiliser AGRID en toute tranquillité, sachant qu'il ne causera aucun dommage à vos installations.
        </p>
    </section>
    `,
        summary:
            'AGRID est non-intrusif et ne peut pas endommager les systèmes CVC. Il agit comme un utilisateur standard.',
        id: 16,
        tags: [ANY, TECHNICAL],
        emoji: '🌡️',
        img: RisksImg,
    },
    {
        label: 'L’interface AGRID ne semble plus fonctionner ?',
        description: `
    <section>
        <p>
            Dans le cas où l’interface AGRID serait momentanément indisponible, cela n’affecte pas le fonctionnement de vos systèmes CVC. 
            Vos unités de chauffage et de climatisation continueront de fonctionner normalement, mais elles ne bénéficieront temporairement pas des optimisations d’économies d’énergie offertes par AGRID.
        </p>
        
        <h3>Que faire en cas d’indisponibilité de l’interface :</h3>
        <ul>
            <li>Vérifiez votre connexion Internet, car une connexion instable peut affecter l’accès à l’interface.</li>
            <li>En cas de problème persistant, redémarrez le boîtier AGRID et essayez de vous reconnecter.</li>
            <li>Si l’interface reste inaccessible, contactez l’équipe de support AGRID pour obtenir de l’aide.</li>
        </ul>
        
        <p>
            Notez que lorsque le service est rétabli, les optimisations d’AGRID reprendront automatiquement pour maximiser les économies d’énergie.
        </p>
    </section>
    `,
        summary:
            "Si AGRID est temporairement indisponible, le système CVC continue de fonctionner mais sans générer d'économies.",
        id: 17,
        tags: [DASHBOARD, TECHNICAL],
        emoji: '🌡️',
        img: BugImg,
    },
    {
        label: 'J’ai besoin d’aide, qui contacter ?',
        description: `
    <section>
        <p>
            Si vous avez besoin d'assistance, vous pouvez contacter différentes personnes en fonction du type de question :
        </p>
        
        <h3>Problèmes liés au système CVC :</h3>
        <p>
            Pour toutes les questions ou problèmes techniques concernant l’équipement CVC lui-même (chauffage, ventilation, climatisation), 
            veuillez contacter directement votre mainteneur. Celui-ci est responsable du bon fonctionnement et de l’entretien des installations CVC.
        </p>
        
        <h3>Questions sur le pilotage et l’utilisation d’AGRID :</h3>
        <p>
            Pour toute question relative à l’utilisation de l’interface AGRID ou à la configuration du pilotage automatique, vous pouvez nous contacter directement 
            par e-mail à l’adresse suivante : <a href="mailto:hello@a-grid.com">hello@a-grid.com</a>. Notre équipe est à votre disposition pour répondre à vos questions 
            et vous aider à tirer le meilleur parti de notre outil.
        </p>
    </section>
    `,
        summary:
            'Contactez votre mainteneur pour les problèmes CVC, ou AGRID pour les questions de pilotage à hello@a-grid.com.',
        id: 18,
        tags: [ANY, RECEPTION],
        emoji: '🌡️',
        img: ContactImg,
    },
    {
        label: 'Configuration de votre site',
        featured: false,
        description: `
    <section>
        <p>
            Lors de la configuration initiale de votre site sur AGRID, il est important de renseigner certaines informations de base pour permettre un pilotage optimal 
            et des économies d’énergie précises.
        </p>
        
        <h3>Étapes de configuration :</h3>
        <ul>
            <li>
                <strong>Surface :</strong> Indiquez la superficie totale de votre établissement pour que le pilotage soit ajusté en fonction de la taille des espaces.
            </li>
            <li>
                <strong>Horaires de Check-Out et de Check-In :</strong> Ces horaires permettent d’optimiser le pilotage CVC en fonction de l’occupation de chaque zone.
            </li>
            <li>
                <strong>Année et consommation de référence :</strong> Ajoutez l’année et la consommation énergétique de référence à partir de laquelle vous souhaitez mesurer les économies réalisées. 
                Nous recommandons d’utiliser la référence définie dans le cadre du décret tertiaire, si votre bâtiment est éligible.
            </li>
            <li>
                <strong>Adresses e-mails des utilisateurs :</strong> Dans la partie droite de l’interface, ajoutez les adresses e-mails des personnes ayant accès à l’outil AGRID 
                pour gérer les consignes et les données.
            </li>
        </ul>
        
        <img src="${Tuto11}" alt="Tuto 1" class="help-image"/>
    </section>
    `,
        summary: `Renseignez les informations de base de votre site, comme la surface, les horaires de Check-Out et de Check-In, l'année de référence et la consommation de référence.`,
        id: 't1',
        tags: [CONFIGURATION],
        emoji: '⚙️',
        img: SiteConfigImg,
    },
    {
        label: 'Configuration des zones',
        featured: false,
        description: `
        <section>
            <p>
                La configuration des zones permet d’organiser et de personnaliser les consignes pour chaque espace de votre site. 
                Vous pouvez assigner un type de pièce à chaque zone (chambres, espaces communs, espaces de restauration, etc.) pour un pilotage plus précis.
            </p>
            
            <h3>Étapes pour configurer les zones :</h3>
            <ol>
                <li>Accédez à l’onglet « Configuration des zones » sur votre interface AGRID.</li>
                <li>
                    Sélectionnez une zone, puis choisissez le type de pièce correspondant dans la liste (« Espaces communs », « Chambres », « Espaces de restauration », etc.).
                </li>
                <li>Vous pouvez glisser-déposer la zone dans la catégorie souhaitée ou cliquer sur les options fournies pour assigner le type de pièce.</li>
            </ol>
            
            <img src="${Tuto21}" alt="Tuto 21" class="help-image"/><br/>
            <img src="${Tuto22}" alt="Tuto 22" class="help-image"/>
            
            <p>
                Cette organisation par zone facilite le pilotage de chaque espace selon ses besoins spécifiques, tout en maximisant les économies d’énergie.
            </p>
        </section>
        `,
        summary: `Définissez le type de pièce pour chaque zone de votre site, comme les chambres, les espaces communs ou les espaces de restauration.`,
        id: 't2',
        tags: [CONFIGURATION],
        emoji: '🏠',
        img: ZoneConfigImg,
    },
    {
        label: "Configuration d'une zone spécifique",
        featured: false,
        description: `En cliquant sur une zone, il sera possible de:<br/>
        - Modifier le nom de la zone si nécessaire (en cliquant sur le nom de la zone).<br/>
        - Définir les bornes de températures de confort. Cool: température de confort en été. Heat: température de confort en hiver.<br/>
        - Indiquer les horaires d’ouverture. Vous pouvez étendre la plage de disponibilité en cliquant sur les flèches, ou bien copier une configuration existante en cliquant sur l'icône à côté du jour.<br/>
        <img src="${Tuto3}" alt="Tuto 3" class="help-image"/>`,
        summary: `Personnalisez les réglages pour chaque zone de votre site, comme les bornes de température de confort et les horaires d'ouverture.`,
        id: 't3',
        tags: [CONFIGURATION],
        emoji: '📍',
        img: ConfigImg,
        video: 'https://www.tella.tv/video/clujt1vjt003j0fli140c6ce5/embed?b=1&title=1&a=1&loop=0&t=0&muted=0&wt=1',
    },
    {
        label: 'Enregistrer pour une zone spécifique',
        featured: false,
        description: `Plusieurs façons d'enregistrer vos modifications:<br/>
        - Pour la zone uniquement: utiliser cette option pour définir des réglages propres à une pièce.<br/>
        - Pour toutes les zones similaires: utiliser cette option pour répéter ces réglages automatiquement sur les autres zones.<br/>
        <img src="${Tuto4}" alt="Tuto 4" class="help-image"/>`,
        summary: `Enregistrez les modifications pour une zone spécifique ou pour toutes les zones similaires.`,
        id: 't4',
        tags: [CONFIGURATION],
        emoji: '💾',
        img: SaveZoneImg,
    },
    {
        label: 'Création de groupes',
        featured: false,
        description: `
        <section>
            <p>
                La fonctionnalité de « Groupe » vous permet de regrouper plusieurs zones pour les gérer et les piloter ensemble. 
                Cela facilite le réglage des consignes de température et le contrôle des systèmes CVC pour des zones similaires, 
                comme l’ensemble des chambres ou des espaces communs, en une seule action.
            </p>
            
            <h3>Étapes pour créer un groupe :</h3>
            <ol>
                <li>Accédez à l’onglet « Configuration » de votre interface AGRID.</li>
                <li>Sélectionnez les zones que vous souhaitez regrouper, par exemple toutes les chambres ou les bureaux.</li>
                <li>Attribuez un nom à ce groupe pour pouvoir le retrouver facilement lors du pilotage.</li>
                <li>Enregistrez le groupe. Une fois créé, vous pourrez ajuster les consignes et enregistrer les réglages pour toutes les zones du groupe en une seule action.</li>
            </ol>
            
            <p>
                Cette organisation par groupes permet également de passer des commandes globales au niveau du groupe (via l’onglet « Pilotage »), 
                simplifiant ainsi la gestion de l’énergie et du confort pour des zones similaires.
            </p>
            
            <img src="${Tuto5}" alt="Tuto 5" class="help-image"/>
        </section>
        `,
        summary: `Regroupez plusieurs zones pour les piloter ensemble et enregistrer les réglages pour toutes les zones du groupe.`,
        id: 't5',
        tags: [CONFIGURATION],
        emoji: '🛠️',
        img: GroupImg,
    },
    {
        label: 'Activation du pilote automatique',
        featured: false,
        description: `
        <section>
            <p>
                Le pilote automatique d’AGRID optimise la consommation énergétique en ajustant les consignes de température de manière autonome. 
                Avant de l’activer, une période d’observation d’environ un mois est nécessaire pour que le modèle thermique se calibre 
                et comprenne les besoins de votre bâtiment.
            </p>
            
            <h3>Étapes pour activer le pilote automatique :</h3>
            <ol>
                <li>Accédez à l’onglet « Configuration du site » de votre interface AGRID.</li>
                <li>Cliquez sur « Démarrer le pilote » pour activer le pilote automatique.</li>
                <li>Une fois activé, le pilote fonctionnera de manière autonome. Vous pourrez cependant l’activer ou le désactiver à tout moment 
                    pour chaque zone selon vos besoins.
                </li>
            </ol>
            
            <p>
                Le pilote automatique optimise ainsi le réglage des températures pour réduire la consommation tout en maintenant le confort des occupants. 
                Il est possible de personnaliser le pilotage zone par zone, offrant une grande flexibilité dans la gestion de l’énergie.
            </p>
            
            <img src="${Tuto6}" alt="Tuto 6" class="help-image"/>
        </section>
        `,
        summary: `Activez le pilote automatique pour optimiser la consommation énergétique de votre site.`,
        id: 't6',
        tags: [CONFIGURATION],
        emoji: '🚀',
        img: PilotImg,
    },
    {
        label: 'Activer/désactiver le pilote pour une zone',
        featured: false,
        description: `
    <section>
        <p>
            Le pilote automatique d’AGRID peut être activé ou désactivé pour une zone spécifique, pour toutes les zones similaires, 
            ou pour un groupe de zones. Cette flexibilité permet d’adapter la gestion thermique aux besoins spécifiques des occupants 
            ou aux circonstances particulières, comme le séjour d’un client.
        </p>
        
        <h3>Étapes pour activer/désactiver le pilote :</h3>
        <ol>
            <li>Accédez à l’onglet « Pilotage » de votre interface AGRID.</li>
            <li>Sélectionnez la zone ou le groupe de zones que vous souhaitez ajuster.</li>
            <li>Cliquez sur l’option pour activer ou désactiver le pilote automatique. Ce réglage peut être appliqué à une seule zone, 
                à toutes les zones similaires, ou à un groupe de zones.
            </li>
        </ol>
        
        <p>
            Cette option est particulièrement utile pour gérer les besoins spécifiques des occupants ou les préférences des clients, 
            tout en maintenant un contrôle précis de la consommation énergétique.
        </p>
        
        <img src="${Tuto7}" alt="Tuto 7" class="help-image"/>
    </section>
    `,
        summary: `Activez ou désactivez le pilote automatique pour une zone spécifique, toutes les zones similaires ou les zones d'un groupe.`,
        id: 't7',
        tags: [CONFIGURATION],
        emoji: '🔄',
        img: ToggleImg,
    },
    {
        label: 'Suivi en temps réel',
        featured: false,
        description: `L’onglet Pilotage permet de voir en temps réel l’usage des systèmes CVC (chauffage, ventilation et climatisation).<br/><br/>
    <h3>Informations générales pour l’hôtel :</h3>
    - Pourcentage de machines allumées : 12.7%<br/>
    - Température moyenne mesurée : 22.3°C<br/>
    - Température moyenne mesurée (dans l’hôtel) : 19.4°C<br/>
    <img src="${Tuto13}" alt="Tuto 8" class="help-image"/><br/>
    Les différentes zone de l’établissement sont regroupées par catégories :  espaces communs - chambres - bureaux ect.) Il est possible de quantifier le nombre de zones associées à chaque catégories ; il apparaît entre parenthèses à côté du nom de la catégorie.<br/><br/>Les deux boutons “pilotés” / “non pilotés” en haut à droite de chaque catégorie permettent d’identifier rapidement les zones étant pilotées de celles ne l’étant pas. <br/><br/>
    <strong> ⚠️ Lorsqu’une zone n’est pas piloté elle apparaîtra dans la partie “non piloté”</strong> <br/><br/>
    Le bouton Pilotage du site permet de passer une consigne pour l’ensemble des zones. (<a href="/help?id=t9">voir la question passer une consigne depuis l’interface</a>) <br/><br/>
    <section>
        <h3>Pour les informations liées à une zone :</h3>
        <p>Ces informations sont présentées à l’aide des pictogrammes visibles sur chaque zone :</p>
        <img src="${Tuto14}" alt="Tuto 8" class="help-image-2" style="max-width: 100%;"/>
        <ul>
            <li>
                <strong>État du système :</strong> Lorsque le rectangle de la zone est allumé et encadré en vert, cela signifie que le système est allumé dans la zone. 
                Si au contraire le rectangle est grisé, le système est donc éteint.
            </li>
            <li>
                <strong>Bornes de températures :</strong> Affichées dans le coin supérieur gauche de chaque zone.
            </li>
            <li>
                <strong>Mode de fonctionnement :</strong> Représenté par :
                <ul>
                    <li>Un soleil (mode chaud). Remarquons ici, que chaque zone est en mode chaud.</li>
                    <li>Un flocon (mode froid).</li>
                </ul>
                <p>Il est possible de voir apparaître :</p>
                <ul>
                    <li>Auto (en jaune) qui signifie que votre système est en mode AUTO</li>
                    <li>FAN (en bleu) qui signifie que votre système est en mode ventilation</li>
                </ul>
            </li>
            <li>
                <strong>Température de consigne :</strong> La température affichée dans le rectangle est la dernière température de consigne passée dans la zone à laquelle elle est relative.
            </li>
            <li>
                <strong>Pilotage des zones :</strong> Indiqué par le pictogramme en haut à droite. S’il est vert, la zone est pilotée ; s’il est blanc, le pilotage est inactif.
            </li>
            <li>
                <strong>Occupation des chambres :</strong> Si votre PMS est relié à AGRID, les informations d’occupation de la chambre apparaissent dans le petit carré dans le coin en bas à droite. 
                Si le carré est gris, la chambre est inoccupée ; s'il est bleu, la chambre est occupée.
            </li>
        </ul>
    </section>
    <section>
        <h3>Lorsque vous cliquez sur une zone, vous accédez au détail:</h3>
        <img src="${Tuto15}" alt="Tuto 8" class="help-image-3" style="max-width: 100%;"/><br/>
        <ul>
            <li>
                La <strong>température intérieure actuelle</strong> apparaît à côté du numéro de la chambre.
            </li>
            <li>
                Le bouton en dessous du numéro de la chambre indique l’<strong>état du système</strong> de climatisation (ON ou OFF).
            </li>
            <li>
                Le <strong>Mode actuel</strong> (froid - chaud - auto - fan - dry) de la chambre est aussi présent.
            </li>
            <li>
                La <strong>température de consigne</strong> est à droite du mode et peut être modifiée en utilisant les boutons + et -. 
                (<a href="/help?id=t9">voir passer une consigne depuis l’interface</a>)
            </li>
            <li>
                Enfin, vous avez à disposition l’information sur la <strong>vitesse de ventilation</strong>.
            </li>
        </ul>
        
        <p>
            En dessus des différentes informations sur la chambre, vous avez accès à un graphique. Il permet d’avoir la température intérieure 
            de la zone/chambre heure par heure sur les dernières 24h. 
        </p>
        
        <p>
            Cela vous permet d’avoir accès à de la donnée de température afin de comprendre ce qu’il s’est passé dans la chambre d’un point de vue thermique sur les dernières 24h.
        </p>
    </section>
    <section>
        <h3>Notre conseil :</h3>
        <p>
            Pour les systèmes CVC (climatisation, ventilation, chauffage) dits 3 tubes, permettant de faire du chaud et du froid à la fois, 
            il est possible de constater que des chambres d’un même hôtel fonctionnent dans des modes différents 
            (par exemple, certaines en mode chaud, d’autres en mode froid, et d’autres en mode auto).
        </p>
        <p>
            Nous vous recommandons de limiter les modes soit à chaud, soit à froid, selon la période. 
            Ce réglage pourra être effectué par notre équipe ou bien par votre mainteneur. Le mode AUTO est extrêmement énergivore, 
            bloquer les modes (en chaud ou en froid) vous permettra de réaliser des économies d’énergie significatives.
        </p>
        <p>
            Pour les systèmes CVC dits 2 tubes permettant de faire uniquement du chaud ou uniquement du froid, 
            nous vous recommandons de ne modifier les modes qu’aux changements de saison sans trop basculer d’un mode à l’autre durant l’intersaison 
            afin de prolonger la durée de vie de vos systèmes.
        </p>
    </section>
    `,
        summary: `Consultez en temps réel l'utilisation des systèmes CVC et passez des consignes pour l'ensemble des zones.`,
        id: 't8',
        tags: [PILOT, DASHBOARD],
        emoji: '🖥️',
        img: Realtime,
    },
    {
        label: 'Passer une consigne depuis l’interface',
        featured: false,
        description: `
        <section>
            <p>
                L’interface AGRID vous permet de passer des consignes directement, offrant un contrôle précis de la température, 
                de la vitesse de ventilation et de l’état de la climatisation dans chaque zone. Vous pouvez également consulter les températures 
                passées et la température mesurée en temps réel pour une gestion optimale.
            </p>
            
            <h3>Étapes pour passer une consigne :</h3>
            <ol>
                <li>Accédez à la zone souhaitée via l’onglet « Pilotage » de l’interface AGRID.</li>
                <li>Consultez la température mesurée en temps réel dans la zone et ajustez les paramètres suivants si nécessaire :</li>
                <ul>
                    <li><strong>Température de consigne :</strong> Modifiez la température cible pour la zone en fonction des besoins de confort des occupants.</li>
                    <li><strong>Vitesse de ventilation :</strong> Ajustez la vitesse de ventilation pour un débit d’air optimal.</li>
                    <li><strong>Statut de la climatisation (On/Off) :</strong> Activez ou désactivez la climatisation pour contrôler la température de manière efficace.</li>
                </ul>
                <li>Enregistrez les modifications pour appliquer les nouveaux réglages à la zone sélectionnée.</li>
            </ol>
            
            <p>
                Cette flexibilité vous permet d’adapter les paramètres de confort et d’économies en fonction des conditions spécifiques de chaque zone. 
                Le suivi en temps réel garantit également une meilleure réactivité et un contrôle précis.
            </p>
            
            <img src="${Tuto9}" alt="Tuto 9" class="help-image"/>
        </section>
        `,
        summary: `Modifiez les températures, la vitesse de ventilation et le statut de la climatisation depuis l'interface.`,
        id: 't9',
        tags: [PILOT, DASHBOARD],
        emoji: '🖱️',
        img: SendCommand,
    },
    // {
    //     label: "Procédure d'urgence",
    //     featured: false,
    //     description: `Il est possible de changer rapidement les paramètres de votre site pour une durée limitée. Cette fonctionnalité est utile en cas d'urgence, par exemple pour un besoin spécifique pour un client.<br/>
    //     Pendant 24h, nous élargissons les bornes lorsque cela est possible et nous désactivons le pilote automatique.<br/>
    //     <img src="${Tuto10}" alt="Tuto 10" class="help-image"/>`,
    //     summary: `Modifiez temporairement les paramètres de votre site en cas d'urgence pour élargir les bornes et désactiver le pilote automatique.`,
    //     id: 't10',
    //     tags: [PILOT],
    //     emoji: '⚠️',
    //     img: Emergency,
    // },

    {
        label: 'Comment connecter mon PMS à AGRID ?',
        featured: false,
        description: null,
        summary: `Vous pouvez connecter votre PMS à AGRID pour synchroniser les données et optimiser les économies d'énergie.`,
        id: 't11',
        tags: [DASHBOARD, OWNER],
        emoji: '💤',
        img: Realtime,
        component: PMSQuestion,
    },
]
