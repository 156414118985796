import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import object_hash from 'object-hash'
import { useNavigate } from 'react-router-dom'
import NoDataComponent from '@/components/Global/no-data.component'
import { getConstraints } from '@/services/constraints.service'
import {
    getBookings,
    getObjectsForSite,
    getTranslations,
    togglePilot,
    warnActionNotPassed,
} from '@/services/realtime.service'
import { isDemo } from '@/services/utils.service'
import { extractFathers } from '@/utils/building.utils'
import { event, pageview } from '@/utils/ga.utils'
import useLocale from '@/utils/locale/locale.hook'
import { getChanges, verifyActionIsPassed } from '@/utils/realtime.utils'
import AlternativeRow from './components/alternative-row.component'
import { useRealtime } from './useRealtime'

const RealTimePage = () => {
    const { site, confirmModifications, confirmDialog, setConfirmDialog, groups } = useRealtime()
    const { hash: siteHash } = site
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [rawObjects, setRawObjects] = useState([])
    const [loading, setLoading] = useState(true)
    const [dialog, setDialog] = useState(false)
    const [modal, setModal] = useState(false)
    const [showAlert, setShowAlert] = useState(null)
    const [sensors, setSensors] = useState(null)
    const [dataMeasurements, setDataMeasurements] = useState(null)
    const dmRef = useRef(dataMeasurements)
    dmRef.current = dataMeasurements
    const [isFetching, setIsFetching] = useState(false)
    const [pilot, setPilot] = useState(false)
    const [buildings, setBuildings] = useState([])
    const navigate = useNavigate()
    const [translations, setTranslations] = useState(null)
    const isFetchingRef = useRef(isFetching)
    isFetchingRef.current = isFetching
    const hashRef = useRef(siteHash)
    hashRef.current = siteHash
    const modalRef = useRef(modal)
    modalRef.current = modal
    const rawRef = useRef(rawObjects)
    rawRef.current = rawObjects
    const [observationEndDate, setObservationEndDate] = useState(null)
    const [selectedBuildingId, setSelectedBuildingId] = useState(null)
    const [unreachable, setUnreachable] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [constraints, setConstraints] = useState([])
    const notifRef = useRef(notifications)
    notifRef.current = notifications
    const locale = useLocale()
    const [objectPms, setObjectPms] = useState([])
    const [lastSetTempDmOld, setlastSetTempDmOld] = useState(null)
    const [bookings, setBookings] = useState([])

    useEffect(() => {
        pageview('/admin/realtime')
    }, [])

    useEffect(() => {
        if (siteHash) {
            setModal(false)
            fetchData(true)
            setSensors(null)
            setRawObjects([])
            setDataMeasurements(null)
            setFirstLoaded(false)
            setSelectedBuildingId(null)
            setObjectPms([])

            let stored_data = localStorage.getItem(`${siteHash}_realtime`)
            if (stored_data) {
                setup(JSON.parse(stored_data))
            }
            let stored_tr = localStorage.getItem(`${siteHash}_translation`)
            if (stored_tr) {
                setTranslations(JSON.parse(stored_tr))
            }
            const interval = setInterval(() => {
                fetchData(false)
            }, 10000)
            setconstraints()
            return () => clearInterval(interval)
        } else {
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [site])

    useEffect(() => {
        if (siteHash) {
            setBookings([])
            _getBookings()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [site])

    const _getBookings = async () => {
        let res = await getBookings(siteHash)
        if (res?.message?.bookings?.length > 0) {
            setBookings(res?.message?.bookings)
        }
    }

    const setconstraints = async () => {
        let res = await getConstraints(siteHash)
        if (res?.constraints) {
            setConstraints(res?.constraints)
        }
    }

    const fetchData = async (init) => {
        let focused = document?.visibilityState
        /** we don't want to fetch data is the user is not looking at his screen or when we display equipment modal */
        if (focused !== 'visible' || (isFetchingRef.current && !init) || modalRef.current) return
        const hash = siteHash

        setIsFetching(true)
        if (hash) {
            if (init) {
                setLoading(true)
                setRawObjects(null)
            }

            const res = await getObjectsForSite({ hash, realtime: true })
            if (res && res.objects && hash == hashRef.current) {
                setUnreachable(false)
                let _objects = res.objects?.filter((el) => !el?.Hidden)
                let changes = getChanges(dmRef.current, res?.dataMeasurements, res?.sensors, _objects)
                setNotifications([...changes, ...notifRef.current])
                // localStorage.setItem(
                //   `${siteHash}_realtime`,
                //   JSON.stringify(res)
                // );
                await setup(res)
                // let actions_failed = [2, 2];
                // let actions_passed = [2, 2];
                let { actions_passed, actions_failed } = verifyActionIsPassed(res?.dataMeasurements)

                if (actions_failed?.length > 0) {
                    setShowAlert('warning')
                    await warnActionNotPassed({
                        siteName: site.name,
                        actions: actions_failed,
                    })
                } else if (actions_passed?.length) {
                    setShowAlert('success')
                }
                if (res?.objectPms) {
                    setObjectPms(res?.objectPms)
                }
                setFirstLoaded(true)
            }
            if (hash == hashRef.current) setLoading(false)
            if (init) {
                const tr = await getTranslations(hash)

                if (tr?.translations) {
                    // localStorage.setItem(
                    //   `${siteHash}_translation`,
                    //   JSON.stringify(tr?.translations)
                    // );
                    setTranslations(tr?.translations)
                }
            }
            let stored_data = localStorage.getItem(`${siteHash}_realtime`)
            if (res && !res?.objects && stored_data) {
                setUnreachable(true)
            }
        }
        setIsFetching(false)
        setFirstLoaded(true)
    }

    const setup = async (res) => {
        let new_hash = object_hash({
            objects: res.objects?.map((el) => {
                return { ...el, fathers: null }
            }),
        })
        let old_hash = object_hash({
            objects: rawRef.current?.map((el) => {
                return { ...el, fathers: null }
            }),
        })
        if (old_hash !== new_hash) {
            let newRaw = res?.objects
                ?.filter((el) => !el?.Hidden)
                ?.map((el) => {
                    let fathers = extractFathers(site, el, res?.objects)
                    return { ...el, fathers }
                })
            setRawObjects([...newRaw])
        }

        let buildings = res?.objects?.filter((el) => el?.ObjectTypeId === 2)
        setBuildings(buildings)
        let siteObject = res.objects?.find((el) => el?.ObjectTypeId === 1)
        if (siteObject) {
            setPilot(siteObject?.Pilot)
        }
        setSensors(res?.sensors)
        setDataMeasurements(res?.dataMeasurements)
        if (res?.dataMeasurements?.length > 0) {
            let filtered_dm = res?.dataMeasurements?.filter((el) => el?.Value > 1)
            let _lastSetTempDmOld = filtered_dm?.[filtered_dm?.length - 1]?.DateTime
            if (_lastSetTempDmOld !== undefined && _lastSetTempDmOld !== null) {
                // check the last set temp dm old is not more than 1 day
                // if it is more than 1 day, we  show the alert
                // if it is less than 1 day, we don't show the alert
                let now = new Date()
                let diff = now - _lastSetTempDmOld
                if (diff > 86400000) {
                    setlastSetTempDmOld(true)
                }
            }
        }

        setObservationEndDate(res?.site?.ObservationEndDate)
    }

    const togglePilotForObjects = async (objectIds, overRideValue) => {
        setLoading(true)
        setDialog(false)
        await togglePilot(siteHash, {
            objectIds,
            overRideValue,
            newValue: 1 - pilot,
            objects: rawObjects,
        })
        let updatedObjects = rawObjects?.map((el) => {
            if (objectIds?.indexOf(el.ObjectId) > -1) return { ...el, Pilot: 1 - pilot }
            else return el
        })
        setRawObjects(updatedObjects)
        setPilot(1 - pilot)
        setLoading(false)
    }

    return (
        <Box>
            {showAlert && (
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={showAlert === 'warning' || showAlert === 'success'}
                    autoHideDuration={6000}
                    onClose={() => setShowAlert(null)}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setShowAlert(null)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                >
                    {showAlert === 'warning' ? (
                        <Alert onClose={() => setShowAlert(null)} severity="warning" sx={{ width: '100%' }}>
                            {locale?.['realTime']?.['error']}
                        </Alert>
                    ) : (
                        <Alert onClose={() => setShowAlert(null)} severity="success" sx={{ width: '100%' }}>
                            {locale?.['realTime']?.['success']}
                        </Alert>
                    )}
                </Snackbar>
            )}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={unreachable}
                // autoHideDuration={6000}
                onClose={() => setUnreachable(false)}
                message="Bâtiment avec une connexion instable"
            />
            {dialog && (
                <Dialog
                    open={dialog}
                    onClose={() => setDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{locale?.['realTime']?.['applyForAll']}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {locale?.['realTime']?.['applyForAllDesc']}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialog(false)}>{locale?.['realTime']?.['cancel']}</Button>
                        <Button
                            onClick={async () => {
                                let site = rawObjects?.find((el) => el?.ObjectTypeId === 1)
                                await togglePilotForObjects([site?.ObjectId])
                            }}
                        >
                            {locale?.['no']}
                        </Button>
                        <Button
                            onClick={async () => {
                                await togglePilotForObjects(
                                    rawObjects.filter((el) => el.Pilot === 0).map((el) => el.ObjectId),
                                    1
                                )
                            }}
                            autoFocus
                        >
                            {locale?.['yes']}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {confirmDialog && (
                <Dialog
                    open={confirmDialog}
                    onClose={() => setConfirmDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{locale?.['realTime']?.['applyForAll']}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {locale?.['realTime']?.['notReco']}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={async () => {
                                event('SendAction', `${site.name} - Full Website Action`)
                                await confirmModifications(null)
                            }}
                            autoFocus
                        >
                            {locale?.['yes']}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {!observationEndDate && firstLoaded && !isDemo() && (
                <Alert severity="info" style={{ marginBottom: 20 }}>
                    {locale?.['realTime']?.['notFinishedYet']}
                </Alert>
            )}
            {lastSetTempDmOld && (
                <Alert severity="warning" style={{ marginBottom: 20 }}>
                    La donnée récupérée est trop ancienne, veuillez contacter Agrid si le problème persiste.
                </Alert>
            )}

            {!modal && (
                <AlternativeRow
                    objectPms={objectPms}
                    bookings={bookings}
                    selectedBuildingId={selectedBuildingId}
                    groups={groups?.filter((el) => {
                        if (buildings?.length <= 1 || !selectedBuildingId) return true
                        else {
                            let el_obj = el.objects
                            for (let i = 0; i < el_obj?.length; i++) {
                                let find = rawObjects?.find((el) => el?.ObjectId === el_obj[i])
                                let fathers = extractFathers(site, find, rawObjects)
                                if (fathers?.indexOf(selectedBuildingId) > -1) {
                                    return true
                                }
                            }
                            return false
                        }
                    })}
                    show={(row) => {
                        console.log('row', row)
                        navigate(`${row.ObjectId}`)
                    }}
                    sensors={sensors}
                    dataMeasurements={dataMeasurements}
                    rawObjects={rawObjects
                        ?.filter((el) => el?.ObjectTypeId !== 3 && el?.Name)

                        ?.filter((el) => {
                            if (buildings?.length <= 1 || !selectedBuildingId) return true
                            else {
                                /** more than one building */
                                return el?.fathers?.indexOf(selectedBuildingId) > -1
                            }
                        })}
                    draggable={false}
                    realtime={true}
                    sitePilot={pilot}
                    translations={translations}
                    showSite={() => {
                        const find = rawObjects?.find((el) => el?.ObjectTypeId == 1)
                        if (find) {
                            navigate(`${find.ObjectId}`)
                        }
                    }}
                    constraints={constraints}
                    hash={siteHash}
                    notifications={notifications}
                />
            )}
            {loading && (
                <div
                    style={{
                        top: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 10000,
                        left: 0,
                        display: 'flex',
                        paddingTop: 100,
                        justifyContent: 'center',
                        position: 'absolute',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress color="inherit" />
                    <div style={{ fontSize: 10, margin: 15 }}>
                        Veuillez patienter, nous accédons à votre site pour récupérer vos informations..
                    </div>
                </div>
            )}

            {!loading && !site ? (
                <Alert severity="warning" style={{ marginBottom: 20 }}>
                    Aucun site n&apos;est encore associé à votre compte, veuillez contacter Agrid pour plus
                    d&apos;informations.
                </Alert>
            ) : null}

            {firstLoaded && (rawObjects?.length === 0 || !rawObjects) && <NoDataComponent navigate={navigate} />}
            {!firstLoaded && <Backdrop open={true} style={{ color: '#fff', zIndex: 1000 }}></Backdrop>}
        </Box>
    )
}

export default RealTimePage
